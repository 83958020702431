<template>
  <div>
    <div class="content-header">
      <img @click="goToMobile"  src="@/assets/images/arrow-left.svg" alt="Logo de Geor Company" class="img-fluid filter icon-fill" width="24">
      <span class="content-header__title">
        Configuración de perfil
      </span>
    </div>
    <v-row style="" class="content-profile">
      <v-col style="width: 468px; max-width: 468px;">
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formProfile">
          <form @submit.prevent="onSubmit" autocomplete="off" class="pt-7">
            <ValidationProvider name="logo" v-slot="{ errors }" vid="avatar"
              style="display: flex;justify-content: center; align-items: center;">
              <UploadLogo @onSetLogo="onSetLogo" :default-image="avatar"></UploadLogo>
              <small class="is-invalid mt-3" v-if="errors">{{ errors[0] }}</small>
            </ValidationProvider>
            <br />
            <br />
            <ValidationProvider name="nombre" rules="required|max:100" v-slot="{ errors }" vid="full_name">
              <v-text-field style="margin-bottom: 10px !important; font-size: 14px !important;" label="Primer nombre" v-model="form.full_name" class="profile"
                :error-messages="errors" onload="this.style.fontSize = '12px';"></v-text-field>
            </ValidationProvider>

            <v-text-field style="margin-bottom: 10px !important; font-size: 14px;" label="Fecha de nacimiento" v-model="form.birthdate" class="profile"
              type="date"></v-text-field>
            <v-select style="margin-bottom: 0px !important; font-size: 14px" v-model="form.genre" :items="genres" item-value="id" class="select-gender profile"
              item-text="text" label="Género" ></v-select>
            <v-btn style="border-radius: 5px; height: 32px; box-shadow: none; padding-inline: 10px !important; margin-bottom: 16px;" color="primary" rounded :disabled="invalid || loading"
              type="submit" :loading="loading" ><span class="text-button-new">Guardar</span></v-btn>
            <div style="border-bottom: 1px solid #E0E0E0; width: 100%; height: 1px;">
            </div>
            <div class="d-flex justify-space-between" style="position: relative; align-items: center; margin-top: 16px;">
              <div style="display: grid; gap: 2px;">
                <span style="font-size: 12px;">Correo electrónico</span>
                <span style="color: #00000099; font-size: 14px;">{{ form.email !== '' ? form.email : 'Ninguno' }}</span>
              </div>
              <v-btn @click=" openModalChangeEmail = true" style="" class="button-profile"><span class="text-button-new">Cambiar</span></v-btn>
            </div>
            <!-- <div class="d-flex justify-space-between"
              style="position: relative; align-items: center; margin-top: 16px; margin-bottom: 24px;">
              <div style="display: grid; gap: 2px;">
                <span style="font-size: 12px;">Número celular</span>
                <span style="color: #00000099; font-size: 14px;">{{ form.phone !== null ? form.phone : 'Ninguno' }}</span>
              </div>
              <v-btn @click="onOpenModalChangePhone" class="button-profile"><span class="text-button-new">Cambiar</span></v-btn>
            </div> -->
            <!-- <div class="d-flex"
              style="position: relative; align-items: center; margin-top: 12px; margin-bottom: 24px;">
              <div @click="goToProfileDelete" style="background-color: transparent; padding: 0px;box-shadow: none;"><span class="text-button-new"
                style="color: #D92D20;">Eliminar cuenta</span></div>
            </div> -->
          </form>
        </ValidationObserver>
      </v-col>
      <ChangeEmail @updateUserEmail="updateUserEmail" :is-open.sync="openModalChangeEmail"></ChangeEmail>
      <ChangePhone @updateUserPhone="updateUserPhone" :is-open.sync="openModalChangePhone"></ChangePhone>
    </v-row>
  </div>
</template>

<script>
import UploadLogo from "@/components/partials/UploadLogo.vue";
import ChangeEmail from "@/components/profile/ChangeEmail.vue";
import ChangePhone from "@/components/profile/ChangePhone.vue";
import { mapState } from "vuex";
// import SelectCountries from "../partials/SelectCountries.vue";

export default {
  components: {
    UploadLogo,
    ChangeEmail,
    ChangePhone,
    // SelectCountries,
  },
  data() {
    return {
      avatar: null,
      openModalChangeEmail: false,
      openModalChangePhone: false,
      form: {
        avatar: null,
        full_name: "",
        email: "",
        phone: "",
        birthdate: "",
        genre: "",
        country_id: "",
      },
      genres: [
        { id: 1, text: "Hombre" },
        { id: 2, text: "Mujer" },
      ],
      countries: [],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  async mounted() {
    this.form.full_name = this.user.full_name;
    this.form.email = this.user.email;
    this.form.phone = this.user.phone;
    this.form.birthdate = this.user.birthdate;
    this.form.genre = this.user.genre;
    this.form.country_id = this.user.country_id;
    this.avatar = this.user.avatar;

    await this.$axios.get("countries").then((response) => {
      this.countries = response.data.countries;
    });
  },
  methods: {
    onCaptureCountry(countryId) {
      const country = this.countries.find((c) => c.id === countryId);
      if (country) {
        this.form.prefix = country.prefix;
      }
    },
    onOpenModalChangePhone() {
      if (this.user.hasEmail) {
        this.openModalChangePhone = true;
      } else {
        const msg1 = "Para poder cambiar tu número de teléfono debes ";
        const msg2 = "ingresar un correo electrónico valido.";
        this.$toast.warning(msg1 + msg2);
      }
    },
    updateUserEmail() {
      const user = this.$auth.user();
      this.form.email = user.email;
    },
    updateUserPhone() {
      const user = this.$auth.user();
      this.form.phone = user.phone;
    },
    onSubmit() {
      this.$axios
        .post("users/profile", this.form)
        .then((response) => {
          this.$toast.success(response.data.message);
          this.$auth.saveUser(response.data.data);
          this.$store.dispatch("REFRESH_USER_DATA");
          this.form.avatar = null;
        })
        .catch((error) => this.showErrors(error, "formProfile"));
    },
    goToMobile() {
      this.$router.push({ name: 'ProfileMobile' });
    },
    goToProfileDelete() {
      this.$router.push({ name: 'ProfileDelete' });
    },
    onSetLogo(file) {
      this.form.avatar = file;
    },
  },
};
</script>
