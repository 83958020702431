<template>
  <v-dialog persistent max-width="390" :value="isOpen" style="margin:0px !important; margin-right: 75px !important;">
    <div v-if="!showVerifyModal">
      <v-card style="display: flex; flex-direction: column; gap: 32px;">
        <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span style="padding: 4px 0px;">Número de celular</span>
            <div style="display: flex;padding: 8px;justify-content: flex-end;align-items: center;cursor: pointer;" @click="onClose" >
              <img src="@/assets/images/icon-close.svg" width="16" />
            </div>
          </div>
        </v-card-title>
        <ValidationObserver tag="div" ref="formChangePhone">
          <form @submit.prevent="onSubmit" autocomplete="off" style="display: flex; flex-direction: column; gap: 18px;">
            <ValidationProvider style="padding: 0px 24px" name="teléfono" rules="required|numeric|min:8|max:12"
              v-slot="{ errors }" vid="phone" ref="phone">
              <v-text-field style="margin-bottom: 0px;" label="Nuevo número de teléfono" v-model="form.phone"
                :error-messages="errors" v-mask="'############'" :readonly="hasSentCode" class="modal-create"></v-text-field>
            </ValidationProvider>
            <div style="display: flex; padding: 0px 24px 4px 24px; justify-content: flex-end;gap:10px;">
              <v-btn
                style="height: 32px; border-radius: 5px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0);
                background-color: transparent;margin-right:0px !important; padding-inline: 10px !important;"
                class="me-5" depressed @click="onClose"><span class="text-button-new">Cancelar</span></v-btn>
              <v-btn style="height: 32px; border-radius: 5px;margin-right:0px !important; padding-inline: 10px !important;" color="primary" class="me-5" :loading="loading"
                depressed @click="openVerifyModal" type="submit"><span class="text-button-new">Confirmar</span></v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-card>
    </div>
    <div v-else>
      <v-card style="display: flex; flex-direction: column; gap: 32px;">
        <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span>Verifica tu número de celular</span>
            <img src="@/assets/images/icon-close.svg" width="24" @click="onClose" />
          </div>
        </v-card-title>
        <ValidationObserver tag="div" ref="formChangePhone">
          <form @submit.prevent="onSubmit" autocomplete="off" style="display: flex; flex-direction: column; gap: 18px;">
            <h6 style="padding: 0px 24px;font-size: 16px;font-style: normal;font-weight: 400;" v-if="message">
              {{ message }}</h6>
            <ValidationProvider style="padding: 0px 24px" v-if="hasSentCode" name="código" rules="required|length:6"
              v-slot="{ errors }" vid="code">
              <v-text-field style="margin-bottom: 0px" label="Código de verificación" v-model="form.code"
                v-mask="'######'" :error-messages="errors"></v-text-field>
            </ValidationProvider>
            <div style="display: flex;padding: 0px 24px 4px 24px;justify-content: flex-end;gap:10px;">
              <v-btn
                style="height: 32px; border-radius: 5px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0);
                background-color: transparent;margin-right:0px !important; padding-inline: 10px !important;"
                class="me-5" depressed @click="onClose"><span class="text-button-new">Cancelar</span></v-btn>
              <v-btn style="height: 32px; border-radius: 5px;margin-right:0px !important; padding-inline: 10px !important;" color="primary"
                :loading="loading" class="me-5" :disabled="loading" type="submit" depressed><span class="text-button-new">Confirmar</span></v-btn>
            </div>
          </form>
        </ValidationObserver>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: {
        phone: "",
        code: "",
      },
      hasSentCode: false,
      message: null,
      showVerifyModal: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  methods: {
    openVerifyModal() {
      // this.showVerifyModal = true;
    },
    onSubmit() {
      if (!this.hasSentCode) {
        this.$axios
          .post("users/send-code-change-phone", this.form)
          .then((response) => {
            this.message = response.data.message;
            this.hasSentCode = true;
            this.showVerifyModal = true;
          })
          .catch((error) => this.showErrors(error, "formChangePhone"));
      } else {
        this.$axios
          .post("users/change-phone", this.form)
          .then(() => {
            const { user } = this;
            user.phone = this.form.phone;
            this.$auth.saveUser(user);
            this.$emit("updateUserPhone");
            this.$store.commit("REFRESH_USER_DATA");
            this.onClose();
            this.message = null;
            this.hasSentCode = false;
            this.showVerifyModal = true;
          })
          .catch((error) => this.showErrors(error, "formChangePhone"));
      }
    },
    onClose() {
      this.form.phone = '';
      if (this.$refs.phone) {
        this.$refs.phone.reset();
      }
      this.showVerifyModal = false;
      this.$emit("update:isOpen", false);
    },
  },
};
</script>
