<template>
  <v-dialog persistent max-width="390" :value="isOpen" style="margin:0px !important; margin-right: 75px !important;" class="prueba">
    <div v-if="!showVerifyModal">
      <v-card style="display: flex; flex-direction: column; gap: 32px;">
        <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span style="padding: 4px 0px;">Nueva dirección de correo</span>
            <div style="display: flex;padding: 8px;justify-content: flex-end;align-items: center; cursor: pointer;" @click="onClose">
              <img src="@/assets/images/icon-close.svg" width="16" />
            </div>
          </div>
        </v-card-title>
        <ValidationObserver
          tag="div"
          ref="formChangeEmail"
        >
          <form @submit.prevent="onSubmit" autocomplete="off" style="display: flex; flex-direction: column; gap: 18px;">
            <ValidationProvider
              style="padding: 0px 24px"
              name="email"
              rules="required|email|max:190"
              v-slot="{ errors }"
              vid="email"
              ref="email"
            >
              <v-text-field
                style="margin-bottom: 0px;"
                label="Nuevo correo"
                v-model="form.email" class="modal-create"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <div style="display: flex; padding: 0px 24px 4px 24px; justify-content: flex-end;gap:10px;">
              <v-btn
                style="height: 32px; border-radius: 8px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0); background-color: transparent;
                margin-right:0px !important;padding-inline: 10px !important;"
                depressed
                @click="onClose"
                class="me-5"
                ><span class="text-button-new">Cancelar</span></v-btn
              >
              <v-btn
                style="height: 32px; border-radius: 8px;margin-right:0px !important; padding-inline: 10px !important;"
                color="primary"
                type="submit"
                class="me-5"
                depressed
                ><span class="text-button-new">Guardar</span></v-btn
              >
            </div>
          </form>
        </ValidationObserver>
    </v-card>
    </div>
    <div v-else>
      <v-card style="display: flex; flex-direction: column; gap: 32px;">
        <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span>Verifica tu dirección de correo</span>
            <div style="display: flex;padding: 8px;justify-content: flex-end;align-items: center; cursor: pointer;" @click="onClose">
              <img src="@/assets/images/icon-close.svg" width="16" />
            </div>
          </div>
        </v-card-title>
        <ValidationObserver tag="div" ref="formChangeEmail">
          <form @submit.prevent="onSubmit" autocomplete="off" style="display: flex; flex-direction: column; gap: 18px;">
            <ValidationProvider
              style="padding: 0px 24px"
              name="código"
              rules="required|length:6"
              v-slot="{ errors }"
              vid="code"
            >
              <v-text-field
                style="margin-bottom: 0px"
                label="Código de verificación"
                v-model="form.code"
                :error-messages="errors"
                class="modal-create"
              ></v-text-field>
            </ValidationProvider>
            <div style="display: flex;padding: 0px 24px 4px 24px;justify-content: flex-end;gap:10px;">
              <v-btn
                style="height: 32px; border-radius: 8px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0); background-color: transparent;
                margin-right:0px !important;padding-inline: 10px !important;"
                class="me-5"
                depressed
                @click="onClose"
                ><span class="text-button-new">Cancelar</span></v-btn
              >
              <v-btn
                style="height: 32px; border-radius: 8px;margin-right:0px !important;padding-inline: 10px !important;" color="primary"
                :loading="loading"
                :disabled="invalid || loading"
                type="submit"
                class="me-5"
                depressed
                ><span class="text-button-new">Guardar</span></v-btn
              >
            </div>
          </form>
        </ValidationObserver>
    </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
        code: "",
      },
      hasPreviousChange: false,
      showVerifyModal: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.$axios
          .get("users/check-previous-change-email")
          .then((response) => {
            const { data } = response.data;
            if (data) {
              this.form.email = data;
              this.hasPreviousChange = true;
            }
          });
      }
    },
  },
  methods: {
    openVerifyModal() {
      this.showVerifyModal = true;
    },
    onSubmit() {
      const payload = {
        email: this.form.email,
      };

      if (this.hasPreviousChange) {
        payload.code = this.form.code;
      }

      this.$axios
        .post("users/change-email", payload)
        .then((response) => {
          const { data } = response;
          const { action } = data;
          if (action === "CODE_SENT") {
            this.hasPreviousChange = true;
          }
          if (action === "EMAIL_CHANGED") {
            const { user } = this;
            user.hasEmail = true;
            user.email = this.form.email;
            this.$auth.saveUser(user);
            this.$emit("updateUserEmail");
            this.onClose();
            this.$store.commit("REFRESH_USER_DATA");
          }
          this.$toast.success(data.message);
          this.showVerifyModal = true;
        })
        .catch((error) => {
          const { data } = error.response;
          if (data.action === "MANY_TRIES") {
            this.onClose();
          }
          this.showErrors(error, "formChangeEmail");
        });
    },
    onClose() {
      this.form.email = '';
      if (this.$refs.email) {
        this.$refs.email.reset();
      }
      this.showVerifyModal = false;
      this.$emit("update:isOpen", false);
    },
  },
};
</script>
